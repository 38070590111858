import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import * as lightbox from '@elements/lightbox';
lightbox.init({
    mobileSettings: {
        controls: true,
        showCloseIcon: true
    }
});

import * as formValidation from '@elements/form-validation';
formValidation.init();

import * as heroSlider from './scripts/hero-slider';
heroSlider.init();