"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, find, findIn, closest, removeAttribute, findAllIn} from "@elements/dom-utils";
import BlazeSlider from 'blaze-slider';
import {translate} from '@elements/translations';

export function init () {
    onFind('.js-hero-slider', function (baseElement) {
        let pause = findIn('.js-hero-slider__pause', baseElement);
        let autoplaySetting = baseElement.getAttribute("data-autoplay")
        let autoplay = false;
        let autoplayTime;
        let interval;

        if (autoplaySetting !== "false") {
            autoplay = true;

            if (autoplaySetting === "5sec") {
                autoplayTime = 5000;
            }
            if (autoplaySetting === "3sec") {
                autoplayTime = 3000;
            }
        }

        let heroSlider = new BlazeSlider(baseElement, {
            all: {
                loop: autoplay,
                slidesToScroll: 1,
                slidesToShow: 1,
                draggable: true,
                enablePagination: true,
                enableAutoplay: autoplay,
                autoplayInterval: autoplayTime,
                transitionTimingFunction: 'linear',
            }
        })

        if(heroSlider.totalSlides <= 1){
            let arrows = findAllIn('.js-hero-slider__arrow', baseElement);
            arrows.forEach((arrow) => {
                arrow.setAttribute("hidden", true)
            })
        }

        if(pause) {
            on('click', (evt) => {
                if (hasClass("paused", pause)) {
                    removeClass("paused", pause)
                    pause.setAttribute("aria-label", translate('hero.autoslide-pause'))

                    let icon = findIn('.icon', pause)
                    removeClass("icon-play", icon)
                    addClass("icon-pause", icon)

                    interval = setInterval(() => {
                        heroSlider.next()
                    }, autoplayTime);
                } else {
                    addClass("paused", pause)
                    pause.setAttribute("aria-label", translate('hero.autoslide-resume'))
                    let icon = findIn('.icon', pause)
                    removeClass("icon-pause", icon)
                    addClass("icon-play", icon)

                    heroSlider.stopAutoplay();
                    clearInterval(interval)
                }
            }, pause)
        }
    });
}
